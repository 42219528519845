import React from "react"
import { graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import Layout from "../components/Layout/Layout"
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import SEO from "../components/Seo/Seo"
import styled from 'styled-components'
import Heading2 from '../components/Type/Heading2'
import Heading4 from '../components/Type/Heading4'
import Paragraph1 from "../components/Type/Paragraph1"

const MakeModelBadgeCarPage = ({ location, data, pageContext }) => {

  const slugger = (part) => {
    return part.replace(/\s+/g, '-').toLowerCase();
  }

  let badgeTxt = (pageContext.badge !== "(No Badge)") ? pageContext.badge : "";

  const carList = data.allValuation.edges.map((edge, i) => {
    let carSlug = edge.node.carName.replace(/\s+/g, '-').toLowerCase();
    return (
      <li key={i}><ButtonLink to={"/price/" + carSlug + "/"}>{edge.node.carName}</ButtonLink></li>
    );
  });

  let breadcrumb = <div style={{ color: 'white' }}>
    Valuations: &nbsp;
    <Link style={{ color: 'white' }} to="/price/">Make</Link> &nbsp; / &nbsp;
    <Link style={{ color: 'white' }} to={slugger("/price/" + pageContext.make + "/")}>{pageContext.make}</Link> &nbsp; / &nbsp;
    <Link style={{ color: 'white' }} to={slugger("/price/" + pageContext.make + "/" + pageContext.model + "/")}>{pageContext.model}</Link> &nbsp; / &nbsp; {pageContext.badge}
  </div>

  return (
    <Layout>

      <SEO title={"Price guide for " + pageContext.make + " " + pageContext.model + " " + badgeTxt + " cars in Australia"}
        description={"How much are " + pageContext.make + " " + pageContext.model + " " + badgeTxt + " cars worth in Australia? Find out here."}
        location={location}
      />

      <PageHeader marginBottom="10px"
        title={"Price guide for " + pageContext.make + " " + pageContext.model + " " + badgeTxt + " cars in Australia"}
        text={"How much are " + pageContext.make + " " + pageContext.model + " " + badgeTxt + " cars worth in Australia? Find out here."}
        breadcrumb={breadcrumb}
      />

      <SectionPageMargin marginBottom="40px">

        <Heading2>{pageContext.make} {pageContext.model} {badgeTxt}</Heading2>

        <Containter>
          <div>
            <MakeLink to={"/price/"} >
              <div style={{ alignSelf: 'center' }}>
                <Img fixed={data.logoImg.childImageSharp.fixed} />
              </div>
            </MakeLink>
          </div>

          <SelectBox>
            <Heading4 color="#3479ae" style={{ margin: '0px' }}>Select a Car</Heading4>
            <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
              {carList}
            </ul>
          </SelectBox>
        </Containter>

        <Paragraph1>
          If we are missing the car you're looking for, it is due to a lack of 
          data in our valuations database to provide a confident valuation. 
          Please make an <Link to="https://value.summon.com.au/">enquiry with us</Link>, and we'll provide a custom 
          Valuation Report for your car.
        </Paragraph1>
        

      </SectionPageMargin>

    </Layout>
  )
}

export default MakeModelBadgeCarPage

const Containter = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const SelectBox = styled.div`

`;

const MakeLink = styled(Link)`
  display: block;
	border: 1px solid #CCCCCC;
	border-radius: 5px;
	text-align: center;
	padding: 10px;
	background: white;
  margin-right: 30px;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    width: 300px;
  }

	&:hover {
		box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
	}

`

const ButtonLink = styled(Link)`
  display: block;
	border: 1px solid #CCCCCC;
	border-radius: 5px;
	text-align: center;
	padding: 10px;
  width: 300px;
	background: #f8f8f9;
  margin-bottom: 8px;
  text-decoration: none;

	&:hover {
		box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    background: #3479ae;
    color: white;
	}

`

export const PageQuery = graphql`
  query($make: String!, $model: String!, $badge: String!, $logo: String!) {

      allValuation(filter: {make: {eq: $make}, model: {eq: $model}, badge: {eq: $badge}}, sort: {order: DESC, fields: year}) {
        edges {
          node {
            carName
            year
            my
          }
        }
      }

      logoImg: file(relativePath: { eq: $logo }) {
        childImageSharp {
          fixed(width: 150) {
          ...GatsbyImageSharpFixed
          }
        }
      }

  }`

